"use client";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { classNames } from "@/helpers/tailwind-class";
import { LinkGroup } from "@/shared/sidebar";

export function ProductSidebar({ linkGroup }: { linkGroup: LinkGroup }) {
  return (
    <Disclosure as="div" className="">
      {({ open }) => (
        <>
          <DisclosureButton className="flex w-full items-center rounded-lg px-3 py-2 text-sm font-medium text-white hover:bg-indigo-600/75">
            {linkGroup.icon}
            {linkGroup.name}
            <ChevronDownIcon
              className={classNames(open ? "rotate-180" : "", "ml-auto h-5 w-5 flex-none")}
              aria-hidden="true"
            />
          </DisclosureButton>
          <DisclosurePanel className="mt-2 space-y-2 rounded-md bg-slate-900 shadow-md">
            {linkGroup.sublinks.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                className="flex items-center gap-1 rounded-lg py-2 pl-4 pr-3 text-xs text-white hover:bg-indigo-600"
              >
                {item.icon}
                {item.name}
              </DisclosureButton>
            ))}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

export function ProductHeader({ linkGroup }: { linkGroup: LinkGroup }) {
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center">
          <MenuButton className="inline-flex w-full items-center justify-center rounded-md px-3 py-1 text-neutral-50 hover:bg-gray-700/50 hover:text-gray-200">
            {linkGroup.name}
            <ChevronDownIcon
              className="-mr-1 ml-2 block h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </MenuButton>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute mt-2 rounded-md bg-neutral-950 p-4 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {linkGroup.sublinks.map((item) => (
              <MenuItem key={item.name}>
                <div className="group relative flex min-w-96 items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-slate-800">
                  <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-slate-800 group-hover:bg-slate-700">
                    {item.icon}
                  </div>
                  <div className="flex-auto">
                    <a href={item.href} className="block font-semibold text-neutral-50">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-neutral-200">{item.description}</p>
                  </div>
                </div>
              </MenuItem>
            ))}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}
