"use client";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, PropsWithChildren, useState } from "react";
import { CreateVideoLink } from "./action-link";
import { ProductSidebar } from "./product-links";
import UserHeader from "@/app/(signed-out)/user-header";
import { classNames } from "@/helpers/tailwind-class";
import { Header, HeaderLogoWithName } from "@/shared/header";

interface Link {
  description?: string;
  name: string;
  href: string;
  icon: JSX.Element;
}

export interface LinkGroup {
  name: string;
  icon: JSX.Element;
  sublinks: Link[];
}

type SidebarLinks = (Link | LinkGroup)[];

interface SideBarProps extends PropsWithChildren {
  framesCount?: number;
  links: SidebarLinks;
  hasStaticSidebar: boolean;
}

export function Sidebar({ children, framesCount, links, hasStaticSidebar }: SideBarProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative flex max-w-[208px] flex-1 flex-col bg-slate-900">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <HeaderLogoWithName />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    <SidebarItems links={links} />
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
            <div className="w-14 flex-shrink-0" aria-hidden="true" />
          </div>
        </Dialog>
      </Transition>

      {hasStaticSidebar && (
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-52 md:flex-col">
          <div className="min-h-0 flex flex-1 flex-col bg-slate-900">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <nav className="mt-16 flex-1 space-y-1 px-2">
                <SidebarItems links={links} />
              </nav>
            </div>
          </div>
        </div>
      )}
      <div className={classNames("flex flex-1 flex-col", hasStaticSidebar ? "md:pl-52" : "")}>
        <div className="sticky top-0 z-40 bg-gray-100 md:hidden">
          <Header
            left={
              <div className="flex flex-shrink-0 items-center">
                <button
                  type="button"
                  className="-ml-0.5 -mt-0.5 inline-flex h-6 w-6 items-center justify-center rounded-md text-gray-500 hover:text-gray-400"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            }
            middle={<HeaderLogoWithName />}
            right={<UserHeader mobileView={true} framesCount={framesCount} />}
            staticStyle={hasStaticSidebar}
          />
        </div>
        {children}
      </div>
    </>
  );
}

function SidebarItems({ links }: { links: SidebarLinks }) {
  return (
    <>
      <CreateVideoLink />
      {links.map((item) => {
        if ((item as LinkGroup).sublinks) {
          return <ProductSidebar key={item.name} linkGroup={item as LinkGroup} />;
        }

        item = item as Link;

        return (
          <a
            key={item.name}
            href={item.href}
            className={classNames(
              "text-white hover:bg-indigo-600/75",
              "group flex items-center rounded-md px-3 py-2 text-sm font-medium"
            )}
            {...(item.href.startsWith("http")
              ? { rel: "noopener noreferrer", target: "_blank" }
              : undefined)}
          >
            {item.icon}
            {item.name}
          </a>
        );
      })}
    </>
  );
}
